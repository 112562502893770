<template>
  <section id="mainContainer" class="main-container com-min-width">
    <el-container>
      <el-header height="8vh">
        <Header v-if="!noHeader" />
      </el-header>
      <el-container>
        <el-aside width="2rem">
          <AsideMenu />
        </el-aside>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </el-container>
  </section>
</template>
<script>
import Header from "@/components/common/Header.vue";
import AsideMenu from "@/components/aside/AsideMenu.vue";
export default {
  components: {
    Header,
    AsideMenu,
  },
  data() {
    return {
      loginBg: require("@/assets/images/common/bg.png"),
      isLogin: false,
      noHeader: false,
      noFoot: false,
      hasTopBanner: false,
      hasPrevious: false,
      scale: 0,
    };
  },

  methods: {},
  created() {
    this.isLogin = this.$route.meta.isLogin;
    this.noHeader = this.$route.meta.noHeader;
    this.noFoot = this.$route.meta.noFoot;
    this.hasTopBanner = this.$route.meta.hasTopBanner;
    this.hasPrevious = this.$route.meta.hasPrevious;
    this.hasSideBox = this.$route.meta.hasSideBox;
  },
  watch: {
    $route(val) {
      if (val) {
        this.isLogin = this.$route.meta.isLogin;
        this.noHeader = val.meta.noHeader;
        this.noFoot = val.meta.noFoot;
        this.hasTopBanner = val.meta.hasTopBanner;
        this.hasPrevious = val.meta.hasPrevious;
        this.hasSideBox = this.$route.meta.hasSideBox;
      }
    },
  },
};
</script>
<style lang="scss">
.main-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.el-header {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  padding: 0;
  min-height: 60px;
}

.el-aside {
  position: relative;
  background-color: #012c57;
  text-align: center;
  min-height: 92vh;
}

.el-main {
  position: relative;
  width: 1720px;
  min-height: 92vh;
  padding: 23px 18px;
  .main-section {
    position: relative;
    height: 85vh;
    overflow-y: auto;
  }
}
</style>
<style lang="scss" scoped>
</style>
