<template>
  <div class="aside-menu-wrap">
    <div v-show="singleMenu" class="single-menus">
      <div @click="routeLink('Index')" class="menu-back">
        <div class="menu-name">返回工作台首页</div>
      </div>
      <div class="menu-list">
        <div
          v-for="(item, i) in singleMenus[menuType]"
          :key="i"
          class="menu-item"
          :class="{ cur: curSingleIndex == i }"
          @click="routeLink(item.routeName)"
        >
          <span class="icon-wrap">
            <i class="ls-icons" :class="[item.iconCss]"></i>
          </span>
          <span class="name">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div v-show="!singleMenu" class="mult-menus">
      <el-menu
        :default-active="curMultIndex"
        background-color="transparent"
        text-color="#FFF"
        active-text-color="#FFF"
      >
        <el-submenu
          v-for="subMenu in multMenus"
          :key="subMenu.menuIndex"
          :index="subMenu.menuIndex"
        >
          <template slot="title">
            <span class="ls-icons" :class="subMenu.iconCss"></span>
            <span>{{ subMenu.title }}</span>
          </template>
          <el-menu-item
            v-for="item in subMenu.chilren"
            :key="item.menuIndex"
            :index="item.menuIndex"
            @click="routeLink(item.routeName)"
          >
            <span class="ls-icons icon-menu-dot"></span>
            <span>{{ item.title }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      singleMenus: {
        account: [
          { iconCss: "icon-account", name: "账号信息", routeName: "Account" },
          { iconCss: "icon-modify-pwd", name: "修改密码", routeName: "ModifyPassword" },
        ],
        wallet: [
          { iconCss: "icon-wallet", name: "我的钱包", routeName: "FuelManage" },
          { iconCss: "icon-list", name: "订单管理", routeName: "OrderMan" },
          { iconCss: "icon-question", name: "常见问题", routeName: "Question" },
        ],
        platform:[
          { iconCss: "icon-lujing", name: "联系平台", routeName: "ReportProblem" },
        ]
      },
      multMenus: [
        {
          menuIndex: "1",
          iconCss: "icon-menu-platform",
          title: "工作台概览",
          chilren: [
            {
              menuIndex: "1-1",
              title: "概览",
              routeName: "Index",
            },
          ],
        },
        {
          menuIndex: "2",
          iconCss: "icon-menu-assetsrv",
          title: "数字资产服务",
          chilren: [
            {
              menuIndex: "2-1",
              title: "服务概览",
              routeName: "ServiceOverview",
            },
            {
              menuIndex: "2-2",
              title: "综合查询",
              routeName: "IntegratedQuery",
            },
          ],
        },
        {
          menuIndex: "3",
          iconCss: "icon-menu-cersrv",
          title: "保全与存证服务",
          chilren: [
            {
              menuIndex: "3-1",
              title: "服务概览",
              routeName: "",
            },
            {
              menuIndex: "3-2",
              title: "保全与存证",
              routeName: "",
            },
          ],
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["asideMenus"]),
    curSingleIndex() {
      return this.asideMenus.curSingleIndex || 0;
    },
    curMultIndex() {
      return this.asideMenus.curMultIndex || '1-1';
    },
    menuType() {
      return this.asideMenus.menuType || "index";
    },
    singleMenu() {
      return this.asideMenus.singleMenu || false;
    }
  },
  methods: {
    routeLink(routeName) {
      if(!routeName) {
        this.$message.info(this.$CommonMsg["building"]);
        return;
      }
      this.$router.push({name: routeName})
    }
  },
};
</script>
<style lang="scss" scoped>
.aside-menu-wrap {
  .menu-back {
    position: relative;
    height: 80px;
    cursor: pointer;
    .menu-name {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      @extend %v-h-center;
      color: #1d8db9;
      white-space: nowrap;
      padding: 7px 28px;
      border-radius: 64px;
      box-sizing: border-box;
      border: 1px solid #1d8db9;
    }
  }
  .menu-list {
    position: relative;
    .menu-item {
      position: relative;
      height: 69px;
      display: flex;
      align-items: center;
      padding-left: 45px;
      cursor: pointer;
      &:hover,
      &.cur {
        background-color: #1d8db9;
      }
      .icon-wrap {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        .ls-icons {
          @extend %v-h-center;
        }
      }
      .name {
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
}

:deep() {
  .el-menu {
    border: 0;
  }
  .el-submenu__title {
    padding: 0 !important;
    padding-left: 21px !important;
    padding-top: 24px;
    padding-bottom: 24px;
    height: 69px;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    .ls-icons {
      margin-right: 8px;
    }
    i {
      color: #ffffff;
    }
    &:hover {
      background-color: #1d8db9 !important;
    }
  }
  .el-submenu__icon-arrow {
    font-size: 12px;
    right: 18px;
    margin-top: -6px;
  }

  .el-menu-item {
    height: 69px;
    line-height: 69px;
    padding: 0 !important;
    min-width: initial;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 60px !important;
    .ls-icons {
      margin-right: 7px;
    }
    &:hover,
    &.is-active {
      background-color: #1d8db9 !important;
    }
  }
}
</style>
